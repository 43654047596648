import React, {useEffect, useState} from "react";
import {HModal} from "../../../ui";
import {TripType} from "../../../types/models";
import {useSelector} from "react-redux";
import {AppState} from "../../../reducers/store";
import {useFormStore} from "../../../ui/hooks/useFormStore";
import {ticketEditModalStructure, ticketModalFields} from "../constants";
import {apiTripLoadData} from "../../../actions/tripsActions";
import {apiClientSearch} from "../../../actions/clientActions";
import {HField, HFormStore, HFormStructure} from "../../../ui/types";
import {apiTicketCreate} from "../../../actions/ticketsActions";

export const preparePhone = (phone: string) => {

  phone = phone.replaceAll(' ', '');
  phone = phone.replaceAll('-', '');
  phone = phone.replaceAll('_', '');

  return phone;

}

export function TicketCreateModal({onClose, onUpdate, open, trip}: {
  onClose: () => void,
  onUpdate: () => void,
  open: boolean,
  trip: TripType
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [formStore, getForm] = useFormStore(ticketModalFields, ticketEditModalStructure)
  const [clientStore, getClientForm] = useFormStore(clientStoreFields, clientStoreStructure)

  const [submitLoading, setSubmitLoading] = useState(false)

  const submit = async () => {

    const isValid = formStore.validate();
    const isClientValid = clientStore.validate();

    console.log(clientStore.getErrors())

    if (isValid && isClientValid) {

      if (preparePhone(clientStore.getValue('phone')).length !== 13) {
        clientStore.setError({
          key: 'phone',
          error: 'Неверный формат телефона'
        })
        return
      }

      if (token) {
        setSubmitLoading(true)
        if (token) {
          const response = await apiTicketCreate(token, trip.id, {
            ...formStore.getValues(),
            ...clientStore.getValues()
          })
          if (response.status) {
            onUpdate()
            formStore.clean()
          } else {
            alert(response.error)
          }
          setSubmitLoading(false)
        }
      }
    }

  }

  return (
    <HModal
      open={open}
      onClose={onClose}
      content={(
        <TicketCreate
          trip={trip}
          formStore={formStore}
          clientFormStore={clientStore}
          getForm={getForm}
          getClientForm={getClientForm}
        />
      )}
      isLoading={submitLoading}
      onSubmit={submit}
      title={'Добавить бронь'}
    />
  )
}

const clientStoreFields: HField[] = [
  {
    key: 'phone',
    component: 'textInput',
    mask: '+375 99 999-99-99',
    placeholder: '+375 __ ___-__-__',
    label: 'Номер телефона',
    required: true
  },
  {
    key: 'firstName',
    component: 'textInput',
    label: 'Имя',
    required: true
  },
  {
    key: 'lastName',
    component: 'textInput',
    label: 'Фамилия',
    required: true
  },
]

const clientStoreStructure: HFormStructure = [
  'phone',
  ['firstName', 'lastName']
]

function TicketCreate({trip, formStore, clientFormStore, getForm, getClientForm}: {
  trip: TripType,
  formStore: HFormStore,
  clientFormStore: HFormStore,
  getClientForm: any,
  getForm: any
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    init()
  }, [trip])

  const init = async () => {

    setLoading(true)

    if (token) {
      const response = await apiTripLoadData(token, trip.id)

      formStore.setValue({
        key: 'places',
        value: 1
      })

      formStore.setProps('stationFrom', {
        elements: response.stationsFrom
      })
      formStore.setValue({
        key: 'stationFrom',
        value: response.stationsFrom[0]
      })

      formStore.setProps('stationTo', {
        elements: response.stationsTo
      })
      formStore.setValue({
        key: 'stationTo',
        value: response.stationsTo[0]
      })

      setLoading(false)
    }

  }

  if (loading) {
    return <div/>
  }

  return (
    <div className="create-ticket-form">
      <div className="create-ticket-form-left">
        <ClientSearch
          formStore={clientFormStore}
          getForm={getClientForm}
        />
      </div>
      <div className="create-ticket-form-right">{getForm()}</div>
    </div>
  )

}

function ClientSearch({
  formStore, getForm
}: {
  formStore: HFormStore,
  getForm: any
}) {

  const token = useSelector((state: AppState) => state.user.token)

  const [loading, setLoading] = useState(false)

  formStore.emitter.subscribe('change.phone', (value: string) => {

    const phone = preparePhone(value)
    if (phone.length === 13) {
      searchClient(phone)
    }

  })

  const searchClient = async (_phone: string) => {

    formStore.setProps('firstName', {
      hidden: true
    })
    formStore.setProps('lastName', {
      hidden: true
    })
    if (token) {
      const response = await apiClientSearch(token, _phone)
      if (response.client !== null) {
        formStore.setValue({
          key: 'firstName',
          value: response.client.first_name
        })
        formStore.setValue({
          key: 'lastName',
          value: response.client.last_name
        })
      }
      formStore.setProps('firstName', {
        hidden: false
      })
      formStore.setProps('lastName', {
        hidden: false
      })
    }

  }

  return (
    <div>
      {getForm()}
    </div>
  )

}
