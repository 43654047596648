import React, {useEffect, useState} from 'react'
import {HField, HFormStructure} from "../../ui/types";
import {useFormStore} from "../../ui/hooks/useFormStore";
import { Button } from '../../ui';
import './Login.scss'
import {LogoImage, PromoImage} from "../../assets";
import {useDispatch, useSelector} from "react-redux";
import {userSignInAction} from "../../reducers/user";
import {AppState} from "../../reducers/store";
import {useNavigate} from "react-router-dom";
import {apiUserAuth} from "../../actions/userActions";

const fields: HField[] = [
  {
    key: 'email',
    component: 'textInput',
    placeholder: 'E-mail',
    label: '',
    required: true
  },
  {
    key: 'password',
    component: 'textInput',
    placeholder: 'Пароль',
    label: '',
    type: 'password',
    required: true
  }
]

const structure: HFormStructure = [
  'email',
  'password'
]

function Login() {

  const dispatch = useDispatch()

  const [formStore, getForm] = useFormStore(fields, structure)
  const [submitLoading, setSubmitLoading] = useState(false)

  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn]);

  const submit = async () => {

    const isValid = formStore.validate()
    if (isValid) {

      setSubmitLoading(true)
      const response = await apiUserAuth({
        email: formStore.getValue('email'),
        password: formStore.getValue('password')
      })
      setSubmitLoading(false)
      if (!response.status) {
        formStore.setError({
          key: 'email',
          error: 'Неверный логин или пароль'
        })
        return;
      }
      dispatch(userSignInAction({
        user: response.user,
        token: response.token,
      }))

    }

  }

  return (
    <div className="login-page">
      <div className="login-page-left">
        <div className="login-page-top">
          <img src={LogoImage} className="login-page-logo" alt=""/>
          <span className="login-page-sub-logo">Диспетчерская программа, управление бронированием и клиентская база</span>
        </div>
        <img src={PromoImage} alt="PROMO IMAGE" className="login-page-promo"/>
        <div className="login-page-bottom">
          <span className="login-page-copyright">© 2023 Система управления <a href="">busmanager.pro</a> </span>
        </div>
      </div>
      <div className="login-page-right">
        <div className="login-page-form">
          <span className="login-page-title">Войти в диспетчерскую</span>
          <span className="login-page-subtitle">Система управления <a href="">busmanager.pro</a></span>
          <div className="login-page-fields">
            {getForm()}
            <Button
              isLoading={submitLoading}
              label={'Войти'}
              type='primary'
              className={'login-page-submit'}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  )

}

export default Login
