import React, {ReactElement, useEffect} from 'react';
import Modal from 'react-modal';
import './Modal.scss';
import {GrClose} from "react-icons/gr";
import {Button} from "../Button/Button";

export type HModalProps = {
  open: boolean,
  onClose: () => void,
  onUpdate?: () => void,
  content?: ReactElement,
  title?: string
  onSubmit?: () => void
  isLoading?: boolean
  headers?: {label: string, value: string}[]
  disableFooter?: boolean
}

const customStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export function HModal({open, onClose, content, title, onSubmit, isLoading, headers, disableFooter}: HModalProps) {

  useEffect(() => {

    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

  }, [open])

  return (
    <Modal
      isOpen={open}
      style={customStyles}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className="h-modal">
        <div className="h-modal-top">
          <div className="h-modal-close" onClick={onClose}>
            <GrClose/>
          </div>
          <span className="h-modal-title">{title}</span>
        </div>
        {headers && (
          <div className="h-modal-header">
            {headers.map(header => {
              return (
                <span className="h-modal-header-element">{header.label}: <b>{header.value}</b></span>
              )
            })}
          </div>
        )}
        <div className="h-modal-content">
          {content}
        </div>
        {!disableFooter && (
          <div className="h-modal-bottom">
            <Button onClick={onClose} label={'Назад'} type={'secondary'}/>
            <Button isLoading={isLoading} onClick={onSubmit} label={'Сохранить'} className={'margin-left-10'}/>
          </div>
        )}
      </div>
    </Modal>
  )

}
