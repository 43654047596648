import React, {ReactElement, useId} from 'react';
import './Label.scss';

type LabelProps = {
  label: string
  required?: boolean
  children: ReactElement,
  error: false | string
}

export function Label({
  label,
  required = false,
  children,
  error
}: LabelProps) {

  const id = useId();

  const field = React.cloneElement(children, {
    id
  });

  return (
    <div className="h-label-area">
      {label && <label htmlFor={id} className={`h-label-area-text ${required && 'h-label-area-text-required'}`}>{label}</label>}
      <div className="h-label-area-content">
        {field}
        {error && <div className="h-label-area-error">{error}</div>}
      </div>
    </div>
  )
}
