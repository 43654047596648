import React from "react";
import {Select} from "../../../ui";

export const statusLabels = ['Отменен', 'Забронирован', 'Потвержден', 'Пришел', 'Не пришел', 'Резерв', 'Не потвердил']
const statuses = [0,1,2,3,4,5,6]

export function StatusSelect({value, onChange}: {
  value: string
  onChange: (value: string) => void
}) {
  return (
    <Select
      value={parseInt(value)}
      placeholder={'Статус'}
      elements={statuses}
      renderValue={(value) => statusLabels[value]}
      onChange={onChange}
    />
  )
}
