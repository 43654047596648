import React from 'react';
import './TextInput.scss';
import InputMask from 'react-input-mask';

type TextInputProps = {
  id?: string
  value: string
  error: string | false
  type?: 'text' | 'email',
  placeholder?: string
  onChange?: (value: string) => void
  mask?: string
}

export function TextInput({
  value,
  type = 'text',
  placeholder,
  onChange,
  id,
  error,
  mask
}: TextInputProps) {

  if (mask) {
    return (
      <InputMask
        mask={mask}
        onChange={(event) => {
          onChange && onChange(event.target.value)
        }}
        value={value}
        placeholder={placeholder}
        className={`h-text-input${error ? ' h-text-input-error' : ''}`}
      />
    )
  }

  return (
    <input
      id={id}
      type={type}
      value={value}
      onChange={(event) => {
        onChange && onChange(event.target.value)
      }}
      placeholder={placeholder}
      className={`h-text-input${error ? ' h-text-input-error' : ''}`}
    />
  );
}
