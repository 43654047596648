import React from 'react';
import './Button.scss';

type ButtonProps = {
  label: string
  disabled?: boolean
  onClick?: () => void
  type?: 'primary' | 'secondary'
  className?: string,
  size?: 'default' | 'small'
  isLoading?: boolean
}

export function Button({
  label,
  disabled = false,
  onClick,
  type = 'primary',
  size = 'default',
  className,
  isLoading
}: ButtonProps) {
  return (
    <button
      disabled={disabled || isLoading}
      className={`h-button ${type === 'primary' && 'h-button-primary'} ${size === 'small' && 'small'} ${className && className}`}
      onClick={() => {
        onClick && onClick()
      }}
    >
      {isLoading && <div className="spinner"/>}
      {label}
    </button>
  );
}
