import {API_URL} from "../constants";
import axios from "axios";
import {preparePhone} from "../pages/Trips/components/TicketCreateModal";

export const apiTicketsGet = (token: string, tripId: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/tickets?trip_id=${tripId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.tickets)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTicketsGetLogs = (token: string, ticketId: number): any => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${API_URL}/tickets/logs?ticket_id=${ticketId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
            .then(function (response) {
                resolve(response.data.logs)
            })
            .catch(function (error) {
                reject(error);
            });
    });
};

export const apiTicketsGetClients = (token: string, clientId: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/tickets/clients?client_id=${clientId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.tickets)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTicketChangeStatus = (token: string, ticketId: number, status: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/ticket/status/change`, {
        id: ticketId,
        status
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.tickets)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTicketUpdate = (token: string, id: number, {
  stationFrom, stationTo, places, comment
}: any): any  => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/ticket/update`, {
        id,
        places,
        comment,
        station_from_id: stationFrom.id,
        station_to_id: stationTo.id
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTicketCreate = (token: string, tripId: number, {
  stationFrom, stationTo, places, comment, firstName, lastName, phone
}: any): any  => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/ticket/create`, {
        phone: preparePhone(phone),
        last_name: lastName,
        first_name: firstName,
        places,
        comment,
        station_from_id: stationFrom.id,
        station_to_id: stationTo.id,
        trip_id: tripId,
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
