import React from 'react';
import './DatePicker.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';

type DateInputProps = {
  id?: string
  value: Date
  placeholder?: string
  onChange: (value: Date | null) => void
  minDate?: Date
  maxDate?: Date
  className?: string
}

export function HDatePicker({
  value,
  placeholder,
  onChange,
  id,
  maxDate,
  minDate,
  className
}: DateInputProps) {
  return (
    <div className={`h-datepicker ${className ? className : ''}`}>
      <DatePicker
        id={id}
        onChange={(date) => onChange(date)}
        selected={value}
        placeholderText={placeholder}
        locale={ru}
        calendarStartDay={1}
        dateFormat={'dd.MM.yyyy'}
        minDate={minDate}
        maxDate={maxDate}
        peekNextMonth
        dropdownMode="select"
      />
    </div>
  );
}
