import './Table.scss'
import {FiEdit2} from "react-icons/fi";
import {BsTrash3} from "react-icons/bs";
import React from "react";

export type HTableHeads = {
  label?: string
  key?: string,
  render?: (value: any) => string
  component?: ((props: {value: string, onChange: (value: string) => void}) => React.ReactElement)
  width?: number
}[]

export type HTableIcon = {
  icon: () => React.ReactElement,
  onClick?: (element: any) => void
}

export type HTableConfig = {
  heads: HTableHeads
  icons?: HTableIcon[]
}

export type TableProps = {
  tableConfig: HTableConfig
  elements: Record<string, any>[]
  num: number
  onEditPress?: (element: any) => void
  onDeletePress?: (element: any) => void,
  onElementChange?: (id: number, key: string, value: string) => void
  type?: 'default' | 'small'
}

export function Table({
  tableConfig,
  elements,
  num,
  onEditPress,
  onDeletePress,
  onElementChange,
  type = 'default'
}: TableProps) {

  const {heads, icons} = tableConfig

  return (
    <div className={`h-table ${type === 'small' && 'small'}`}>
      <table className="h-table-table">
        <thead>
          <tr>
            {heads.map(head => {
              return (
                <th style={{
                  width: `${head.width ? `${head.width}px` : 'auto'}`
                }}>
                  <span>{head.label}</span>
                </th>
              )
            })}
            {(onDeletePress || onEditPress) && (
              <th style={{width: "80px"}}/>
            )}
          </tr>
        </thead>
        <tbody>
        {elements.map((element, index) => {
          return (
            <tr>
              {heads.map(head => {

                const value = head.key ? element[head.key] : ''

                if (head.component && head.key) {
                  return (
                    <td>
                      {head.component({
                        value: element[head.key],
                        onChange: (value) => {
                          onElementChange && head.key && element['id'] && onElementChange(element['id'], head.key, value)
                        }
                      })}
                    </td>
                  )
                }

                if (head.render) {
                  return <td><span>{head.render(value)}</span></td>
                }

                return <td><span>{value}</span></td>
              })}
              {(onDeletePress || onEditPress) && (
                <td>
                  <div className="h-table-icons">
                    {icons && icons.map(icon => {
                      return (
                          <div className="h-table-icon" onClick={() => {icon.onClick && icon.onClick(element)}}>{icon.icon()}</div>
                      )
                    })}
                    {onEditPress && <div className="h-table-icon" onClick={() => {onEditPress(element)}}><FiEdit2/></div>}
                    {onDeletePress && <div className="h-table-icon" onClick={() => {onDeletePress(element)}}><BsTrash3/></div>}
                  </div>
                </td>
              )}
            </tr>
          )
        })}
        </tbody>
      </table>
      {/*
      <div className="h-table-bottom">
        <span className="h-table-bottom-label">Записи с 1 по x из {num}</span>
      </div>
      */}
    </div>
  )
}
