import React, {useEffect, useState} from 'react';
import './App.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import Cars from "./pages/Cars/Cars";
import Drivers from "./pages/Drivers/Drivers";
import Stations from "./pages/Stations/Stations";
import Cities from "./pages/Cities/Cities";
import Schedules from "./pages/Schedules/Schedules";
import Prices from "./pages/Prices/Prices";
import Trips from "./pages/Trips/Trips";
import Login from "./pages/Login/Login";
import {Provider} from "react-redux";
import {store} from "./reducers/store";
import {userSession} from "./actions/userActions";
import {userSignInAction} from "./reducers/user";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "cars",
        element: <Cars/>,
      },
      {
        path: "drivers",
        element: <Drivers/>,
      },
      {
        path: "stations",
        element: <Stations/>,
      },
      {
        path: "cities",
        element: <Cities/>,
      },
      {
        path: "schedules",
        element: <Schedules/>,
      },
      {
        path: "trips",
        element: <Trips/>,
      },
      {
        path: "/",
        element: <Trips/>,
      },
      {
        path: "prices",
        element: <Prices/>,
      },
    ],
  },
  {
    path: "/login",
    element: <Login/>,
  }
]);

function App() {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    checkSession();
  }, [])

  const checkSession = async () => {

    const session = await userSession();
    if (session === null) {
      setLoading(false);
    } else {
      store.dispatch(userSignInAction({
        user: session.user,
        token: session.token
      }));
      setLoading(false);
    }

  }

  if (loading) {
    return <div/>
  }

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
