import {HModal} from "../../../ui";
import React, {useEffect, useState} from "react";
import {Table} from "../../../ui/components/Table";
import {logsTableConfig} from "../constants";
import {apiTicketsGetLogs} from "../../../actions/ticketsActions";
import {useSelector} from "react-redux";
import {AppState} from "../../../reducers/store";
import moment from "moment";
import {statusLabels} from "./StatusSelect";

export default function LogModal({open, onClose, ticketId}: {
    open: boolean,
    onClose: () => void,
    ticketId?: number
}) {
    return (
        <HModal
            open={open}
            onClose={onClose}
            content={(
                ticketId ? <LogModalContent ticketId={ticketId}/> : <></>
            )}
            title={'История заявки'}
            disableFooter={true}
        />
    )
}

const generateAction = (log: any) : string => {

    if (log.type === 'create') {
        return 'Создал бронь'
    }

    if (log.type === 'change_status') {
        const values = JSON.parse(log.value)
        if (values && values.length > 0) {
            return `Изменил статус брони на "${statusLabels[values[0].value]}"`
        }
    }

    return ''
}

const getUser = (log: any) : string => {

    if (log.user) {
        if (log.user.type === 'client') {
            return `Клиент`
        }
        if (log.user.type === 'admin') {
            return `Менеджер ${log.user.email}`
        }
        if (log.user.type === 'driver' && log.user.driver) {
            return `Водитель ${log.user.driver.fio}`
        }
    }

    return ''

}

function LogModalContent({ticketId}: {ticketId: number}) {

    const token = useSelector((state: AppState) => state.user.token)
    const [logs, setLogs] = useState([])

    useEffect(() => {
        loadLogs()
    }, [ticketId]);

    const loadLogs = async () => {

        if (token) {
            const logs = await apiTicketsGetLogs(token, ticketId)
            setLogs(logs.map((log: any) => {
                return {
                    action: generateAction(log),
                    who: getUser(log),
                    date: moment(log.created_at).format('DD.MM.YYYY HH:mm')
                }
            }))
        }

    }

    return (
        <Table
            tableConfig={logsTableConfig}
            elements={logs}
            num={logs.length}
            type={'small'}
        />
    )

}