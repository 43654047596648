import React, {useEffect, useState} from 'react';
import './Schedules.scss';
import {weeks} from "../../constants";
import {RouteType, ScheduleType} from "../../types/models";
import RouteSelect from "../../components/fields/RouteSelect";
import {apiScheduleCreate, apiScheduleDelete, apiSchedulesGet, apiScheduleUpdate} from "../../actions/scheduleActions";
import {useModal} from "../../ui";
import {generateUpdateMethod} from "../../utils/generateUpdateMethod";
import {generateCreateMethod} from "../../utils/generateCreateMethod";
import {DataSetModal} from "../../ui/components/DataSet/DataSet";
import {modalStructure} from "./structure";
import {fields} from "./fields";
import ScheduleElement from "./components/ScheduleElement";
import ScheduleWeek from "./components/ScheduleWeek";
import {generateDeleteMethod} from "../../utils/generateDeleteMethod";
import {useSelector} from "react-redux";
import {AppState} from "../../reducers/store";

export default function Schedules({}) {

  const [route, setRoute] = useState<RouteType | null>(null)
  const [schedules, setSchedules] = useState<any>([])

  const [scheduleModalOpen, openScheduleModal, closeScheduleModal, scheduleModalObj] = useModal()

  const token = useSelector((state: AppState) => state.user.token)

  useEffect(() => {
    loadSchedules()
  }, [route])

  const loadSchedules = async () => {

    if (route && token) {
      const response = await apiSchedulesGet(token, route.id)
      setSchedules(response)
    }

  }

  const deleteSchedule = async (id: number) => {

    const deleteMethod = generateDeleteMethod(token, apiScheduleDelete)
    await deleteMethod(id)
    loadSchedules()

  }

  return (
    <>
      <DataSetModal
        title={'Расписание'}
        open={scheduleModalOpen}
        onClose={closeScheduleModal}
        onUpdate={() => {
          loadSchedules()
          closeScheduleModal()
        }}
        fields={fields}
        structure={modalStructure}
        plugins={[]}
        type={scheduleModalObj?.type === 'update' ? 'update' : 'create'}
        obj={scheduleModalObj?.type === 'update' ? scheduleModalObj : {
          route_id: route ? route.id : null,
          week: scheduleModalObj?.week
        }}
        headers={[
          {
            label: 'Маршрут',
            value:  route ? route.name : ''
          },
          {
            label: 'День недели',
            value:  scheduleModalObj ? weeks[scheduleModalObj.week] : ''
          }
        ]}
        method={scheduleModalObj?.type === 'update' ? generateUpdateMethod(token, apiScheduleUpdate) : generateCreateMethod(token, apiScheduleCreate)}
      />
      <div className="page-top">
        <div className="page-top-left">
          <span className="page-title">Расписание</span>
        </div>
        <div className="page-top-right">
          <RouteSelect
            width={'300px'}
            value={route}
            onChange={(route) => {setRoute(route)}}
          />
        </div>
      </div>
      <div className="page-content">
        {route !== null && (
          <div className="schedules">
            <div className="schedules-top">
              {Object.keys(weeks).map(week => (
                <ScheduleWeek
                  week={{label: weeks[parseInt(week)], key: parseInt(week)}}
                  onCreatePress={() => {
                    openScheduleModal({type: 'create', week})
                  }}
                />
              ))}
            </div>
            <div className="schedules-content">
              {Object.keys(weeks).map((week) => {
                return (
                  <div className="schedules-content-col">
                    {schedules[week] && schedules[week].map((schedule: ScheduleType) => {
                      return (
                        <ScheduleElement
                          schedule={schedule}
                          onEditPress={() => {
                            openScheduleModal({
                              type: 'update',
                              ...schedule
                            })
                          }}
                          onDeletePress={() => {
                            deleteSchedule(schedule.id)
                          }}
                        />
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )

}
